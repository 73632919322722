import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
      <div>
        <h2>GOT QUESTIONS?</h2>
        <p>
        You can contact us at philip.chenauxrepond(at)gmail.com
        </p>
      </div>
    );
  }
}
 
export default Contact;